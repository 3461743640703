<template>
  <div class="upgrading-page">
    <div class="upgrading-page__content">
      <img class="upgrading-page__image" src="@/assets/images/upgrading.png" alt="升级中...">
      <div class="upgrading-page__message">网页升级维护中，敬请期待 …</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Upgrading"
}
</script>

<style lang="scss" scoped>
.upgrading-page {
  width: 100vw;
  height: 100vh;
  &__content {
    width: 536px;
    height: 502px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  &__message {
    margin-top: 28px;
    height: 24px;
    font-size: 22px;
    font-family: Heiti SC-Medium, Heiti SC， sans-serif;
    font-weight: 500;
    color: #707070;
    line-height: 24px;
    text-align: center;
  }
}
</style>
